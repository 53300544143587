<template>
  <div class="copy-wrap">
    <icon @click="copyValue" :data="copy" width="26" height="26" dir="up" role="button"
          :class="isCopied?'text-success':'text-secondary'"/>
    <span class=" ml-1 copy-title">{{ title }}</span>
    <input
        ref="input"
        :value="isCopied?'Copied Successfully!':value"
        class="copy-text"
        type="text"
        style="width: 100%;"
    />
  </div>
</template>
<script>
import copy from "@fa/solid/copy.svg";

export default {
  name: "Copytext",
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      copy,
      isCopied: false
    }
  },
  methods: {
    copyValue() {
      if (this.isCopied) return;
      const copyText = this.$refs.input;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      copyText.blur();
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 2000);
    }
  }
};
</script>
<style lang="scss" scoped>
.copy-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;

  input[type="text"] {
    background: transparent;
  }
}

.copy-title {
  flex: 1;
  font-weight: bold;
}

.copy-text {
  border: 0;
  outline-width: 0;
  pointer-events: none;
  width: auto;
}
</style>

